import { createResource, createSignal, For, JSX, splitProps, Show } from "solid-js";
import { createStore, } from "solid-js/store";
import { useParams } from "@solidjs/router";
import { CardWithMinimize } from '../components/AppMenuBar/CardList'
import { isExtraSmall } from "../../utils/sizeUtil"

import { Tabs, Tab } from '../components/SmallComponents/SmallTabs';

import { Typography, Button, Grid, Select, MenuItem, ListItemText, IconButton, Stack, Card, Divider, Box,  } from "@suid/material";
import { UploadSocialMediaLink, ShowSocialMedia } from '../components/SmallComponents/SmallSocialMedia';

import { SelectChangeEvent } from "@suid/material/Select";

import {FieldEmail} from "../components/SmallComponents/SmallFieldEmail";

import { AddIcon, CloseIcon } from '../components/icons'; 
import { AvatarChipWithIcon, AvatarChipWithLabel, WFChip } from '../components/SmallComponents/SmallChips'; 
import { AccountMenu } from '../components/AppMenuBar/AppBarMenu';
import { ShowHeading } from '../components/SmallComponents/SmallHeader'
import { ContractRates } from '../components/MediumComponents/MediumContractRatesNew'

import { TeamMembersList } from '../components/SmallComponents/SmallFieldTeamMembers';
import { CountrySelect } from '../components/SmallComponents/SmallFieldCountryCodeList';
import { IndustryList } from '../components/MediumComponents/IndustriesComponent';
import { CompetenceList } from '../components/SmallComponents/SmallChipsCompetence'
import { FieldLanguageList } from '../components/SmallComponents/SmallFieldLanguageList';
import { FieldTextValue } from "../components/SmallComponents/SmallFieldText";
import { FieldWorkModel }from "../components/SmallComponents/SmallFieldWorkModel";
 
import styled from "@suid/material/styles/styled";

import { getDummyAvatar }from '../../utils/getdummyavatar'
import { getText } from '../components/SmallComponents/Translate'
import { useNavigate } from "@solidjs/router";
import { isLoggedIn } from '../../utils/isloggedin'

import {  CandidateCompetenciesFetchType } from "../../types/candidateCVDataTypes"


const apiUrl = import.meta.env.VITE_APP_API_URL;


interface CustomerJobRoleEditProps {

    jobName: string;
    jobDescription: string;
    showForCandidates: string;
    rateValue: string;
    workModel: string;
    remoteWork: string;

    setWorkModel: (newValue: string) => void;
    setHours: (newValue: string) => void;
    setAllocation: (newValue: string) => void;
    setOnSiteAllocation: (newValue: string) => void;
    setRemoteAllocation: (newValue: string) => void;

    setRateModel: (newValue: string) => void;
    //  children?: JSX.Element;
}

const fetchCompetencies = async (candidateId: string | null): Promise<CandidateCompetenciesFetchType> => {
    const response = await fetch(`${apiUrl}/candidate/competencies/${candidateId}`, {
        method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
        throw new Error('Competencies response was not ok');
    }
    const data = await response.json();
    return data;
}


const ShowCustomerJobRoleEditPage = (props: CustomerJobRoleEditProps) => {

    const navigate = useNavigate();

    if (!isLoggedIn()) {
        navigate('/login');
        return;
    }


    const params = useParams();

    const fetchId = params.id ? params.id : "0";


    const [competencies] = createResource<CandidateCompetenciesFetchType>(() => fetchCompetencies(fetchId));

    const [activeTab, setActiveTab] = createSignal<number>(0)


    // const [local] = splitProps(props, ['children'])
    const [state, setState] = createStore({
        workModel: 'Remote',
        remoteWork: '3',
        rateModel: 'Simple',
        expenseModel: 'AllInclusive',
        fromDate: '2023-05-01',
        toDate: '2023-05-15',
        calendarDays: 0,
        allocation: '8',
        allocationOnSite: '0',
        allocationRemote: '0',
        allocationUnits: 'Hours' || 'Days',
        allocationUnitsPr: 'Day' || 'Week' || 'Month',
        currency: 'EUR',
        ratePer: 'Hour' || 'Day' || 'Week' || 'Month',
        rateValue: 0,
        remoteRateValue: 0,
        onSiteRateValue: 0,
        expenseValue: 0,
        remoteExpenseValue: 0,
        onSiteExpenseValue: 0,
        totalHours: 0,
        totalOnSiteHours: 0,
        totalRemoteHours: 0,

        sumRates: '0.00',
        sumExpenses: '0.00',
        sumTotal: '0.00',
    });


    const [country, setCountry] = createSignal('');
    const handleInputChange = (key: string, value: string) => {
        const setValue = props[key as keyof CustomerJobRoleEditProps];

        if (typeof setValue === "function") {
            const setValue = props[key as keyof CustomerJobRoleEditProps] as ((newValue: string) => void) | undefined;
            if (setValue) {
                setValue(value);

            }
        } else {
            const setValue = props[key as keyof CustomerJobRoleEditProps] as ((newValue: string) => void) | undefined;
            if (setValue) {
                setValue(value);
            }

            //  throw new Error(`Tried to set prop "${key}", but it's not a function. Got: ${setValue}`);
        }
    };
    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);

        window.history.go(-1); return false;
    }

    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);
    };

    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);
    const [socialMedia, setSocialMedia] = createSignal("presentation");
    const handleSocialMedia = (value: string) => {
        setSocialMedia(value);
    }
    const Input = styled("input")({
        display: "none",
    });



    const [userName, setUserName] = createSignal('newUser@findasap.team');
    const [firstName, setFirstName] = createSignal('');
    const [lastName, setLastName] = createSignal('');
    const [mobile, setMobile] = createSignal('');


    const [userRoles, setUserRoles] = createSignal(['Administrator', 'Manager', 'Viewer', 'User', 'Recruiter', 'Legal', 'Billing'])
    const [activeUserRole, setActiveUserRole] = createSignal('User')

    const [jobContactRoles, setJobContactRoles] = createSignal(['Recruiter', 'Director', 'Project Manager', 'Manager', 'HR', 'Assistant', 'Procurment', 'Legal', 'Billing'])
    const [activeJobContactRole, setActiveJobContactRole] = createSignal('Recruiter')

    const handleChangeUserRole = (event: SelectChangeEvent) => {

        const newValue = event.target.value;
        setActiveUserRole(newValue)
    }; 



    const txtHeaderactive = getText("jobpage", "headeractive")
    const txtDetails = getText("jobpage", "details")
    const txtJobdescription = getText("jobpage", "jobdescription")
    const txtContacts = getText("jobpage", "contacts")
    const txtRoles = getText("jobpage", "roles")
    const txtRates = getText("jobpage", "rates")
    const txtJobname = getText("jobpage", "jobname")
    const txtOnsiteaddress = getText("jobpage", "onsiteaddress")
    const txtOthers = getText("default", "others")
    const txtMandatorylanguages = getText("jobpage", "mandatorylanguage")
    const txtJobvisibel = getText("jobpage", "jobvisible")
    const txtWorkmodel = getText("jobpage", "workmodel")
    const txtSocial = getText("jobpage", "social")
    const txtUrlsocial = getText("jobpage", "urlsocial")
    const txtUpload = getText("default", "upload")
    const txtJobcompany = getText("jobpage", "jobcompany")
    const txtAboutus = getText("jobpage", "aboutus")
    const txtWhatoffer = getText("jobpage", "whatweoffer")
    const txtTechcompetence = getText("jobpage", "techcompetence")
    const txtPersoncompetence = getText("jobpage", "personcompetence")
    const txtAddperson = getText("jobpage", "addperson")
    const txtAdduser = getText("jobpage", "adduser")
    const txtAddAnotherUser = getText("jobpage", "addanotheruser")
    const txtInvite = getText("jobpage", "invite")
    const txtEmail = getText("contact", "email")
    const txtFirstname = getText("contact", "firstname")
    const txtLastname = getText("contact", "lastname")
    const txtSendInvite = getText("jobpage", "sendinvite")
    const txtTeamMembers = getText("jobpage", "teammembers")
    const txtRolename = getText("jobpage", "rolename")
    const txtRoleDescription = getText("jobpage", "roledescription")
    const txtRoleVisible = getText("jobpage", "rolevisible")
    const txtCountries = getText("default", "countries")
    const txtCountry = getText("default", "country")
    const txtIndustries = getText("industrycomponent", "header")
    const txtCompetencies = getText("jobpage", "competencies")
    const txtEkstraRoleinfo = getText("jobpage", "extraroleinfo")
    const txtWeexpect = getText("jobpage", "weexpect")
    const txtEkstrainfo = getText("jobpage", "extrainfo")

    //  <ShowHeading pxValue={4} >{props.children}</ShowHeading>
    return (
        <>
            <AccountMenu />
            <ShowHeading pxValue={4} >Edit Job Role</ShowHeading>

            <Divider />

            {/*   */}

            <Card sx={{ margin: 2, display: 'flexgrow' }}>
                <Stack direction="column" alignItems="top" justifyContent="space-between" sx={{ px: 2, display: 'flex' }} >
                    {/* Addition navigation options for the customer */}
                    <Box sx={{ display: 'flex', bgcolor: 'background.default'[200] }} >
                        <IconButton onClick={handleClose} > <CloseIcon /> </IconButton>

                        <Tabs activeTab={activeTab()} setActiveTab={setActiveTab}>
                            <Tab>{txtDetails()}</Tab>
                            <Tab>{txtJobdescription()}</Tab>
                            <Tab>{txtContacts()}</Tab>
                           
                            <Tab>{txtRates()}</Tab>

                        </Tabs>

                    </Box>


                    <Grid container spacing={1} style={{ width: '100%' }}>

                        <Grid item xs={12} md={12}>

                            <Divider />
                            <Card >
                                <Stack direction="column" alignItems="left" sx={{ py: 1, bgcolor: 'background.default' }} >
                                    <Show
                                        when={activeTab() === 0}
                                    >
                                        <Stack direction="column" alignItems="left" margin={1} spacing={2} >
                                            <Grid container  >
                                                <Grid item xs={12} md={6} spacing={2}  >
                                                    <Stack direction="column" alignItems="left" margin={2} spacing={2} >
                                                        <FieldTextValue
                                                            id="rolename" label={txtRolename()} textValue="IT Project Manager"
                                                            valueOnChange={value => handleInputChange("roleName", value)}
                                                        />
                                                        <FieldTextValue
                                                            id="roledescription" label={txtRoleDescription()} multiline={true} textValue="Manage the technical  team"
                                                            valueOnChange={value => handleInputChange("roleDescription", value)}
                                                        />


                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12} md={6}>

                                                    <CardWithMinimize header={txtOthers() as string} type="list" defaultOpen={true}>
                                                        <Stack margin={1}>
                                                            <FieldLanguageList id="mandatoryLanguages" label={txtMandatorylanguages() as string} />

 
                                                            <CountrySelect defaultValue={'da'} id="countryId" label={txtCountry() as string} type="Country" valueOnChange={value => setCountry(value)} />
                                                        </Stack>
                                                    </CardWithMinimize>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <CardWithMinimize header={txtSocial() as string} type="list" defaultOpen={true}>

                                                        <Stack direction="row" spacing={2}>
                                                            <UploadSocialMediaLink />

                                                        </Stack>

                                                    </CardWithMinimize>
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <CardWithMinimize header={txtIndustries() as string} type="list" defaultOpen={true}>

                                                        <IndustryList />
                                                    </CardWithMinimize>
                                                </Grid>

                                                {/* List of Candidates different types of competencies */}
                                                <Show
                        when={competencies()?.languages?.length || competencies()?.methods?.length || competencies()?.roles?.length || competencies()?.technicals?.length}
                    >
                                                <Grid item xs={12} >
                                                    <CardWithMinimize header={txtCompetencies() as string} type="list" defaultOpen={true}>


                                                        <Grid container justifyContent="space-around" {...isExtraSmall() ? { direction: 'column' } : { direction: 'row' }}  >


                                                            <Grid item  >

                                                                <CompetenceList showAddButton={false} type="method" direction={isExtraSmall() ? "column" : "row"} header="Methods" options={competencies()?.methods} />

                                                            </Grid>

                                                            <Grid item  >

                                                               
                                                                <CompetenceList showAddButton={false} type="role" direction={isExtraSmall() ? "column" : "row"} header="Roles" options={competencies()?.roles}  />

                                                            </Grid>

                                                            <Grid item  >
 
                                                                <CompetenceList showAddButton={false} type="technical" direction={isExtraSmall() ? "column" : "row"} header="Technical" options={competencies()?.technicals}  />

                                                            </Grid>
                                                        </Grid>

                                                    </CardWithMinimize>
                                                </Grid>
                                                </Show>
                                            </Grid>
                                        </Stack>
                                    </Show>


                                    <Show
                                        when={activeTab() === 1}
                                    >
                                        <Stack direction="column" alignItems="left" margin={2} spacing={2} >
                                            <Typography variant="subtitle1">{txtEkstraRoleinfo() as string}</Typography>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}>

                                                <Grid container spacing={2} direction={isExtraSmall() ? "column" : "row"} style={{ width: '100%' }}>
                                                    <Grid item xs={12} md={6}>
                                                        <FieldTextValue
                                                            id="aboutus" label={txtWeexpect()} textValue="" multiline={true}
                                                            valueOnChange={value => handleInputChange("weExpect", value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <FieldTextValue
                                                            id="addInfo" label={txtEkstrainfo()} textValue="" multiline={true}
                                                            valueOnChange={value => handleInputChange("JobRoleInformation", value)}
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </Box>
                                        </Stack>
                                    </Show>

                                    <Show
                                        when={activeTab() === 2}
                                    >
                                        
                                        <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}>
                                                <Grid container spacing={1} style={{ width: '100%' }}>

                                                    <Grid item xs={12} md={6}>

                                                        <CardWithMinimize header={txtContacts() as string} type="list" defaultOpen={true}>

                                                            <Stack direction="column" spacing={2} margin={2}>
                                                                <AvatarChipWithIcon url={getDummyAvatar()} >Terry Nelson, Project Manager</AvatarChipWithIcon>
                                                                <AvatarChipWithIcon url={getDummyAvatar()} >Benny Morison, Recruitment Manager</AvatarChipWithIcon>
                                                                <AvatarChipWithIcon url={getDummyAvatar()} >Henrik Henriksen, Assistant, (Invite Sent)</AvatarChipWithIcon>
                                                            </Stack>


                                                            <Typography sx={{ px: 2 }} variant="subtitle1">{txtAddperson()}</Typography>


                                                            <Stack direction={isExtraSmall() ? "column" : "row"} spacing={2} margin={2}>
                                                                <TeamMembersList id="teamMemberId" label={txtTeamMembers() as string} />

                                                                <Select
                                                                    id="addAnotherExternalUserId"
                                                                    value={activeJobContactRole()}
                                                                    defaultValue={activeJobContactRole()}
                                                                    label="Job Contact Role"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onChange={handleChangeUserRole}
                                                                >
                                                                    {jobContactRoles().map((option, index) => (
                                                                        <MenuItem
                                                                            value={option}
                                                                        >
                                                                            <ListItemText>
                                                                                {option}
                                                                            </ListItemText>
                                                                        </MenuItem>
                                                                    ))}

                                                                </Select>

                                                                <Button variant="contained" component="span" startIcon={<AddIcon />}>
                                                                    {txtAdduser()}
                                                                </Button>

                                                            </Stack>
                                                        </CardWithMinimize>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CardWithMinimize header={txtAddAnotherUser() as string} type="list" defaultOpen={true}>
                                                            <Stack direction="column" alignItems="left" margin={2} spacing={2}>

                                                                <Typography variant="subtitle1">{txtInvite()}</Typography>

                                                                <FieldEmail
                                                                    id="inviteEmail" label={txtEmail()} textValue="" width={230}
                                                                    valueOnChange={value => setMobile(value)}
                                                                />

                                                                <Stack direction={isExtraSmall() ? "column" : "row"} alignItems="left" spacing={1}  >


                                                                    <FieldTextValue
                                                                        id="firstName" label={txtFirstname()} textValue={txtFirstname() as string} width={230}
                                                                        valueOnChange={value => setFirstName(value)}
                                                                    />
                                                                    <FieldTextValue
                                                                        id="lastName" label={txtLastname()} textValue={txtLastname() as string} width={230}
                                                                        valueOnChange={value => setLastName(value)}
                                                                    />
                                                                </Stack>

                                                                <Select
                                                                    value={activeUserRole()}
                                                                    id="invitedUserRoleId"
                                                                    defaultValue={activeUserRole()}
                                                                    label="Role"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onChange={handleChangeUserRole}
                                                                >
                                                                    {userRoles().map((option, index) => (
                                                                        <MenuItem
                                                                            value={option}
                                                                        >
                                                                            <ListItemText>
                                                                                {option}
                                                                            </ListItemText>
                                                                        </MenuItem>
                                                                    ))}

                                                                </Select>

                                                                <Button variant="outlined"  >{txtSendInvite()}</Button>
                                                            </Stack>
                                                        </CardWithMinimize>


                                                    </Grid>
                                                </Grid>
                                            </Box>



                                    </Show>

                                 


                                    <Show
                                        when={activeTab() === 3}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>

                                                <CardWithMinimize header={txtWorkmodel() as string} type="list" defaultOpen={true}>
                                                    <FieldWorkModel
                                                        workModel={state.workModel}
                                                        remoteWork={state.remoteWork}
                                                        setWorkModel={value => handleInputChange("setWorkModel", value)}
                                                        setRemoteWork={value => handleInputChange("setRemoteWork", value)}
                                                    />
                                                </CardWithMinimize>

                                            </Grid>

                                            <Grid item xs={12} md={6}>

                                                <CardWithMinimize header={txtRates() as string} type="list" defaultOpen={true}>

                                                    {/* Contract Rates - both simple and advanced*/}
                                                    <ContractRates
                                                        calendarDays={state.calendarDays}
                                                        showAllocation={false}                 
                                                        numberOfHours={String(state.totalHours)}
                                                        setHours={(newValue) => handleChange('totalHours', newValue)}
                                                        numberOfOnSiteHours={state.allocationOnSite}
                                                        numberOfRemoteHours={state.allocationRemote}
                                                        setOnSiteAllocation={(newValue) => handleChange('allocationOnSite', newValue)}
                                                        setRemoteAllocation={(newValue) => handleChange('allocationRemote', newValue)}

                                                        allocation={state.allocation}
                                                        setAllocation={(newValue) => handleChange('allocation', newValue)}
                                                        allocationUnits={state.allocationUnits}
                                                        setAllocationUnits={(newValue) => handleChange('allocationUnits', newValue)}
                                                        allocationUnitsPr={state.allocationUnitsPr}
                                                        setAllocationUnitsPr={(newValue) => handleChange('allocationUnitsPr', newValue)}

                                                        rateModel={state.rateModel}
                                                        setRateModel={(newValue) => handleChange('rateModel', newValue)}
                                                        expenseModel={state.expenseModel}
                                                        setExpenseModel={(newValue) => handleChange('expenseModel', newValue)}

                                                        currency={state.currency}
                                                        setCurrency={(newValue) => handleChange('currency', newValue)}

                                                        ratePer={state.ratePer}
                                                        setRatePer={(newValue) => handleChange('ratePer', newValue)}

                                                        rateValue={state.rateValue.toString()}
                                                        remoteRateValue={state.remoteRateValue.toString()}
                                                        onSiteRateValue={state.onSiteRateValue.toString()}
                                                        setRateValue={(newValue) => handleChange('rateValue', newValue)}
                                                        setRemoteRateValue={(newValue) => handleChange('remoteRateValue', newValue)}
                                                        setOnSiteRateValue={(newValue) => handleChange('onSiteRateValue', newValue)}

                                                        expenseValue={state.expenseValue.toString()}
                                                        remoteExpenseValue={state.remoteExpenseValue.toString()}
                                                        onSiteExpenseValue={state.onSiteExpenseValue.toString()}
                                                        setExpenseValue={(newValue) => handleChange('expenseValue', newValue)}
                                                        setRemoteExpenseValue={(newValue) => handleChange('remoteExpenseValue', newValue)}
                                                        setOnSiteExpenseValue={(newValue) => handleChange('onSiteExpenseValue', newValue)}

                                                        totalHours={state.totalHours}
                                                        totalRemoteHours={state.totalRemoteHours}
                                                        totalOnSiteHours={state.totalOnSiteHours}

                                                        sumRates={state.sumRates}
                                                        sumExpenses={state.sumExpenses}
                                                        sumTotal={state.sumTotal}
                                                        setSumRates={(newValue) => handleChange('sumRates', newValue)}
                                                        setSumExpenses={(newValue) => handleChange('sumExpenses', newValue)}
                                                        setSumTotal={(newValue) => handleChange('sumTotal', newValue)}
                                                    />
                                                </CardWithMinimize>
                                            </Grid>
                                        </Grid>
                                    </Show>



                                </Stack>
                            </Card >
                        </Grid>


                    </Grid>
                </Stack>
            </Card>
        </>
    );
}

export {ShowCustomerJobRoleEditPage}